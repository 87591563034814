.paper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(216, 214, 214, 0.38);
  box-shadow: 5px 5px 15px 5px rgba(226, 225, 225, 0.38);
  padding: 10px 30px 15px 30px;
  margin-bottom: 40px;
  color: #404760;
}
.paper-link {
  text-decoration: none;
  color: inherit;
}
.paper-title {
  padding-top: 15px;
  margin-bottom: 0;
}
.tags-and-date {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
}
.paper-date {
  margin: 0;
  color: #898f9e;
  font-size: 16px;
  font-weight: 100;
}
.paper-base {
  color: #878787;
  font-weight: 500;
  margin-top: 5px;
}
.tags {
  display: flex;
  height: fit-content;
  font-size: 14px;
}
.tag {
  padding: 7px 10px;
  background-color: #f9ebd8;
  color: #666777;
  height: fit-content;
  margin-right: 10px;
  min-width: 80px;
  text-align: center;
  border-radius: 3px;
}
.authors-and-download-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 0;
}

.authors {
  margin: 0;
  font-weight: 300;
}
.paper-download-buttons {
  display: flex;
}
.download-paper-pdf,
.download-paper-bibtex {
  text-decoration: none;
  font-size: 18px;
  padding: 5px 12px;
  width: 100px;
  height: 28px;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-paper-pdf {
  background-color: #99d2d1;
  color: white;
  margin-right: 10px;
}

.download-paper-bibtex {
  border: 2px solid #a2dad9;
  height: 24px;
  color: #83cecd;
}

.download-paper-pdf:hover {
  background-color: #43dcd6;
  box-shadow: 0px 10px 15px rgba(167, 237, 251, 0.4);
}

.download-paper-bibtex:hover {
  border: 2px solid #43dcd6;
  box-shadow: 0px 10px 15px rgba(167, 237, 251, 0.4);
  color: #43dcd6;
}
.play-icon {
  width: 18px;
  margin-right: 10px;
}
.watch-presentation-link {
  text-decoration: none;
}
.presentation-button {
  text-decoration: none;
  font-size: 18px;
  padding: 5px 12px;
  width: fit-content;
  height: 28px;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #a2dad9;
  height: 24px;
  color: #83cecd;
}

.button-disabled,
.button-disabled:hover {
  color: rgba(216, 214, 214, 0.38);
  border: 2px solid rgba(216, 214, 214, 0.38);
  box-shadow: none;
  cursor: not-allowed;
}

@media only screen and (max-width: 670px) {
  .paper-title {
    font-size: 28px;
  }
  .paper-base {
    font-size: 18px;
  }
  .paper {
    padding: 10px 15px 10px 15px;
  }
  .authors-and-download-buttons {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .authors {
    margin-right: auto;
  }
  .paper-download-buttons {
    margin: 10px 0 0 auto;
  }
  .paper {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 560px) {
  .paper-title {
    font-size: 26px;
  }
}
