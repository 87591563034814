.team-page--wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 80px;
  color: #25313c;
  font-family: "Cabin", sans-serif;
}
.the-team-text {
  color: #55626c;
  line-height: 1.5rem;
  max-width: 80%;
}
.macocu {
  height: 40px;
  width: 40px;
  margin: 10px 0 0px 10px;
}
.team-members--container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 30px 0;
  column-gap: 30px;
}

@media only screen and (max-width: 980px) {
  .team-page--wrapper {
    padding: 10px 60px;
  }
}

@media only screen and (max-width: 670px) {
  .team-page--wrapper {
    padding: 10px 40px;
  }
  .team-members--container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 30px 0;
    column-gap: 30px;
  }
  .the-team-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 560px) {
  .team-page--wrapper {
    padding: 10px 30px;
  }
  .team-members--container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 30px 0;
    column-gap: 30px;
  }
}
@media only screen and (max-width: 490px) {
  .team-members--container {
    display: flex;
    flex-direction: column;
  }
}
