.process-section {
  max-width: 1300px;
  margin: 100px auto 0 auto;
  color: #25313c;
}
.processes-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.process-section--paragraph {
  max-width: 90%;
  line-height: 28px;
  font-size: 18px;
  margin: 10px 0 60px 0;
}
.process-section--title {
  font-size: 42px;
  margin-bottom: 10px;
}

.monolingual-cards--title,
.parallel-cards--title {
  text-transform: uppercase;
  color: #6e7071;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.07em;
}

@media only screen and (max-width: 980px) {
  .processes-container {
    flex-direction: column;
  }
  .process-section--paragraph {
    max-width: 90%;
  }
  .monolingual-cards--title,
  .parallel-cards--title {
    font-size: 24px;
  }
  .process-parallel--corpora {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 670px) {
  .process-section--title {
    font-size: 36px;
  }
  .process-section--paragraph {
    max-width: 100%;
  }
  .monolingual-cards--title,
  .parallel-cards--title {
    font-size: 22px;
  }
}
