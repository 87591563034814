.navbar {
  color: #25313c;
  background-color: white;
  padding: 25px 80px;
  position: sticky;
  top: 0;
  z-index: 20;
  font-family: "Cabin", sans-serif;
}
.macocu-logo--navbar {
  width: 220px;
}
.upcoming-workshop-button {
  background-color: #0d999430;
  padding: 7px 18px;
  border-radius: 3px;
}
.navbar-active {
  box-shadow: 0px 10px 15px rgba(218, 218, 218, 0.4);
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.navbar--links {
  display: flex;
  align-items: center;
  margin-top: -10px;
}
.navbar-link {
  margin: 10px 10px 10px 20px;
  font-size: 18px;
  text-decoration: none;
  color: #2c3946;
  font-weight: 550;
  transition: all 0.5s;
}

.navbar-link:hover {
  color: #43dcd6;
}
.navlink-active {
  color: #43dcd6;
  font-weight: 600;
}

@media only screen and (max-width: 980px) {
  .navbar {
    padding: 20px 60px;
  }
  .navbar-link {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .navbar {
    display: none;
  }
}
