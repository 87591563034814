.process-card {
  max-width: 80%;
  padding: 30px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  margin-bottom: 30px;
}

.monolingual-card {
  background-color: #f2ffff;
}

.parallel-card {
  background-color: #fff8ee;
}
.enumeration-and-title {
  display: flex;
  align-items: flex-end;
}
.process-card-number {
  margin: 0;
  font-size: 80px;
  color: #ccc6be;
}
.process-card-title {
  margin-bottom: 15px;
  margin-left: 5px;
  color: #6e7071;
}
.process-card--text {
  margin: 0;
  color: #44495c;
  font-size: 16px;
  line-height: 28px;
}
.hyperlink {
  text-decoration: none;
  color: rgb(82, 204, 241);
}
@media only screen and (max-width: 980px) {
  .process-card {
    max-width: 100%;
    padding: 20px;
    margin-bottom: 20px;
  }
  .process-card-number {
    font-size: 70px;
  }
  .process-card-title {
    margin-bottom: 15px;
    font-size: 22px;
    color: #6e7071;
  }
  .process-card--text {
    margin: 0;
    color: #44495c;
    font-size: 16px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 670px) {
  .process-card {
    padding: 15px;
  }
  .process-card-number {
    font-size: 60px;
  }
  .process-card-title {
    font-size: 20px;
  }
  .process-card--text {
    margin: 0;
    color: #44495c;
    font-size: 16px;
    line-height: 26px;
  }
}
