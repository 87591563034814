.member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}
.member-data {
  max-width: 80%;
  text-align: center;
}
.member-profile-picture {
  max-width: 185px;
}
.member-name {
  margin-bottom: 0;
  color: #25313c;
}
.member-role {
  margin: 6px 0 12px 0;
  color: #878787;
  line-height: 1.41;
}
.affiliation {
  margin: 4px 0;
  color: #878787;
  font-weight: 600;
}
.member-linkedin,
.member-github {
  width: 26px;
  height: 26px;
}

.icon-member {
  margin: 10px 5px;
}
.member-icons {
  margin-top: 30px;
  bottom: 0;
}

@media only screen and (max-width: 980px) {
  .member-data {
    max-width: 90%;
    text-align: center;
  }
  .member-profile-picture {
    max-width: 165px;
  }
  .member-name {
    font-size: 20px;
  }
}
