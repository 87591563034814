.program-table-container {
  width: 100% !important;
  overflow-x: auto;
}

.program-table {
  border-collapse: collapse;
  margin: 10px 0 30px 0;
  min-width: 100%;
  text-align: left;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.118);
}
.table-middle {
  width: 45%;
}
.program-table thead {
  background-color: #fff6f2;
}
.program-table tbody {
  color: #10152f;
}
.program-table tr:nth-of-type(even) {
  background-color: rgb(248, 248, 248);
}
.program-table tr:last-of-type td {
  padding: 12px 15px 20px 15px;
}
.program-table tbody tr {
  border-bottom: 1px solid #e7e7e7;
}
.program-table th {
  padding: 15px 15px;
}
.program-table td {
  padding: 12px 15px;
  font-size: 16px;
  line-height: 1.4;
}
.table-bold {
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .program-table td {
    padding: 12px 15px;
    font-size: 14px;
    line-height: 1.4;
  }
}
