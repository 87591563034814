.question-title-container {
  width: fit-content;
}
.question-title {
  cursor: pointer;
  font-weight: 400;
  margin-left: 7px;
}
.question-title:hover {
  color: #b7a3aa;
}
.answer-container {
  background-color: #fff6f2;
  color: rgb(67, 70, 81);
  padding: 15px 40px;
  border-radius: 10px;
  font-weight: 300;
  font-size: 17px;
  margin-bottom: 50px;
}
.answer-list {
  font-family: "Lora", serif;
}
.answer-paragraph:hover {
  text-decoration: none;
}
.question-title-container {
  display: flex;
  align-items: center;
}
.answer-paragraph ul li span {
  font-weight: 900;
}
.answer-paragraph ul li {
  margin: 15px 0;
}

@media only screen and (max-width: 750px) {
  .answer-container {
    background-color: #fff6f2;
    color: rgb(67, 70, 81);
    padding: 15px 20px;
    border-radius: 10px;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 480px) {
  .answer-paragraph ul li {
    margin: 0;
  }
}
