.title--first-section {
  font-size: 62px;
  max-width: 80%;
  margin: 60px 0 40px 0;
}
.first-section--text {
  max-width: 70%;
}
.first-part--first-section {
  display: flex;
}
.project--title {
  margin-top: 90px;
  text-align: left;
  font-size: 42px;
}

.project-info--title {
  font-size: 20px;
  margin: 5px 0 0 0;
}
.project-info--text {
  margin-top: 5px;
}
.first-section--container {
  max-width: 1300px;
  margin: auto;
  color: #25313c;
}

.project--info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 20px 0;
}

.first-section--text {
  text-align: left;
}
.eval-reports-container {
  width: fit-content;
}
.get-corpora--button {
  color: white;
  background-color: #f4a33a;
  padding: 10px 16px;
  border: none;
  height: 24px;
  border-radius: 4px;
  font-size: 18px;
  text-decoration: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  margin-right: 5px;
}
.eval-reports-button {
  color: #ea8e14;
  border: 2px solid #ea8e14;
  height: 21px;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  display: flex;
  justify-content: center;
}
.hidden-reports {
  display: none;
}
.eval-reports {
  width: fit-content;
  z-index: 1000;
  position: absolute;
  margin-top: 0.1px;
  border: 1px solid #ea8e14;
  border-radius: 3px;
  background-color: white;
}
.report {
  color: #637584;
  width: 210px;
  padding: 10px 0 10px 10px;
  transition: all 0.2;
  border-radius: 3px 3px 0 0;
}
.report:hover {
  background-color: #ffeeda;
}
.first-report {
  border-bottom: 1px solid #ced3d7;
}
.get-corpora--button:hover,
.evaluation-report--button:hover {
  background-color: #fbb14f;
  box-shadow: 0px 10px 15px rgba(244, 215, 143, 0.4);
  color: #fff;
}

.project-info--single {
  text-align: left;
  max-width: 370px;
}

.first-section--illustration {
  margin: 7vh 7vw 0 0;
}

.project-info--single p {
  font-size: 18px;
  color: #44495c;
  margin-right: 10px;
}

/*--- Responsiveness --*/
@media only screen and (max-width: 1022px) {
  .title--first-section {
    font-size: 52px;
    max-width: 85%;
    margin: 60px 0 40px 0;
  }
}

@media only screen and (max-width: 980px) {
  .title--first-section {
    font-size: 48px;
    max-width: 90%;
    margin: 30px 0 40px 0;
  }

  .get-corpora--button {
    max-width: 70%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .eval-reports-container {
    width: 70%;
  }
  .eval-reports-button {
    font-size: 16px;
    width: 98.5%;
  }
  .project--info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
  }
  .first-section--illustration {
    max-width: 36vw;
    margin: 10px;
  }

  .project--title {
    font-size: 32px;
  }
  .project-info--title {
    font-size: 16px;
  }
  .project-info--single p {
    font-size: 18px;
  }
  .first-section--buttons {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 670px) {
  .first-part--first-section {
    display: flex;
  }
  .get-corpora--button,
  .evaluation-report--button {
    min-width: 50%;
  }
  .first-section--text {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .title--first-section {
    font-size: 40px;
    line-height: 38px;
    max-width: 100%;
    margin: 10px 0 30px 0;
    text-align: left;
  }
  .first-section--buttons {
    display: flex;
    align-items: flex-start;
  }

  .first-section--illustration {
    margin: 10px auto;
  }
  .project--info {
    display: flex;
    flex-direction: column;
  }
  .project-info--single {
    max-width: 90%;
  }
  .project--title {
    font-size: 28px;
    margin: 40px 0 10px 0;
  }
}
@media only screen and (max-width: 560px) {
  .homepage-wrapper {
    padding: 10px 30px;
  }
  .get-corpora--button,
  .evaluation-report--button {
    max-width: 90%;
    text-align: center;
    margin: 0 auto 10px 0;
  }
  .first-section--buttons {
    display: flex;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 460px) {
  .first-part--first-section {
    flex-direction: column;
  }
  .get-corpora--button {
    width: 90%;
  }
  .eval-reports-container {
    width: 100%;
  }
  .eval-reports-button {
    font-size: 16px;
    width: 89%;
  }
}
