.homepage-wrapper {
  padding: 10px 80px;
  font-family: "Cabin", sans-serif;
}

#corpora-section {
  transition-delay: 1.3;
}

@media only screen and (max-width: 980px) {
  .homepage-wrapper {
    padding: 10px 60px;
  }
}

@media only screen and (max-width: 670px) {
  .homepage-wrapper {
    padding: 10px 40px;
  }
}

@media only screen and (max-width: 560px) {
  .homepage-wrapper {
    padding: 10px 30px;
  }
}
