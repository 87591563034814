.download-card--wrapper {
  max-width: 280px;
  border: 2px solid #e2e2e2;
  text-align: left;
  padding: 10px;
  margin: 0 10px 10px 0px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.download-button {
  text-decoration: none;
  background-color: #00c4bf;
  border-radius: 5px;
  font-size: 20px;
  color: white;
  padding: 8px 10px;
  margin-top: 0.7vh;
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.corset-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #a2dad9;
  height: 24px;
  color: #83cecd;
  text-decoration: none;
  border-radius: 5px;
  font-size: 20px;
  padding: 8px 10px;
  margin-top: 1vh;
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.corset-icon {
  width: 18px;
  margin-left: 10px;
}
.download-button:hover {
  background-color: #43dcd6;
  box-shadow: 0px 10px 15px rgba(167, 237, 251, 0.4);
  color: #fff;
  transform: translateY(-3px);
}
.corset-button:hover {
  box-shadow: 0px 10px 15px rgba(187, 244, 255, 0.4);
  transform: translateY(-3px);
}

.sentences,
.download-size {
  color: #55626c;
  margin: 10px 0;
}

.tokens,
.download-size {
  color: #55626c;
  margin: 10px 0;
}

.download-card--tag {
  background-color: #dee8f1;
  margin-right: 10px;
  padding: 4px 7px;
  border-radius: 3px;
}
.card-tags {
  display: flex;
}
.card-tags p {
  margin: 3px 3px 0 3px;
}
.card-language {
  color: #25313c;
}

@media only screen and (max-width: 980px) {
  .card-language {
    font-size: 20px;
  }
  .card-tags p {
    margin: 3px 3px 0 3px;
    font-size: 14px;
  }
  .download-button {
    font-size: 17px;
  }
}
