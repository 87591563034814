.papers-page {
  padding: 10px 80px;
  font-family: "Cabin", sans-serif;
}

.papers-container {
  max-width: 1300px;
  margin: 20px auto 60px auto;
}

.cite-macocu {
  margin-left: 30px;
  color: #404760;
  display: flex;
}
.cite-macocu-title {
  margin: 0;
}
.copy-buttons {
  display: flex;
  flex-direction: column;
}
.cite-macocu-paragraph {
  font-size: 20px;
  color: #898f9e;
}
.cite-macocu-link {
  text-decoration: none;
  color: #00c4bf;
}
.papers-titles {
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
}
.publications--title,
.presentations--title {
  color: #404760;
  font-size: 26px;
  background-color: white;
  border: none;
  font-weight: 600;
  margin: 20px 0px 10px 0px;
}
.publications--title:hover,
.presentations--title:hover {
  cursor: pointer;
  opacity: 0.8;
}
.presentations--title {
  margin-left: 5px;
}
.title-active {
  color: #00c4bf;
}
.copy-title,
.copy-bibtex {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.copy-bibtex {
  margin-top: 20px;
}
.bibtex-paragraph {
  margin-bottom: 0;
  max-width: 65%;
  line-height: 1.4;
}

.copy-icon {
  margin-left: 10px;
}
.copy-macocu-title {
  max-width: 70%;
  margin: 30px 0 0 0;
  font-weight: 300;
}
.copy-button {
  border: 0.5px solid #ccd0d9;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 4px 12px;
  margin-right: 3vw;
}
.copy-button:hover {
  cursor: pointer;
  background-color: #1cf5ee38;
}
.macocu-poster {
  max-width: 350px;
}
.copy-icon {
  width: 16px;
}
@media only screen and (max-width: 980px) {
  .papers-page {
    padding: 10px 60px;
  }
  .cite-macocu {
    flex-direction: column;
    margin-left: 0;
  }
  .macocu-poster-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 670px) {
  .papers-page {
    padding: 10px 40px;
  }
  .papers-page--title {
    font-size: 28px;
    margin-left: 15px;
  }
  .publications--title,
  .presentations--title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 560px) {
  .papers-page {
    padding: 10px 30px;
  }
  .copy-title,
  .copy-bibtex {
    flex-direction: column;
    align-items: flex-start;
  }
  .copy-macocu-title {
    max-width: 100%;
    margin: 10px 0 0 0;
  }
  .copy-button {
    margin-top: 20px;
  }
  .bibtex-paragraph {
    min-width: 100%;
  }
}
