.footer-wrapper-home {
  text-align: left;
  margin: 80px auto 0 auto;
  max-width: 1300px;
  padding: 10px 80px;
  font-family: "Cabin", sans-serif;
}
.footer-home {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}
.footer-icon {
  max-width: 90%;
}
.footer-information-home {
  max-width: 32%;
}
.footer-information-home a {
  text-decoration: none;
  color: #25313c;
}
.footer-info-last-p {
  color: #8e8e93;
  font-size: 15px;
  line-height: 21px;
}
.visitor-map {
  margin-top: 30px;
}
.visitor-count-p {
  margin: 10px 0 3px 10px;
}
.partners {
  max-width: 30%;
}
.eu-flag-home {
  width: 60px;
}
.roadmap-footer {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  max-width: 28%;
}
.eu-financing-p-home {
  display: flex;
}
.eu-financing-p-home p {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

.milestone-roadmap {
  display: flex;
  border-left: 2px solid #00c4bf;
  height: fit-content;
}
.milestone-header {
  margin: 0 0 3px 0;
  color: #25313c;
}

.partners-logo--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dot-roadmap {
  width: 18px;
  height: 18px;
  border: 2px solid #00c4bf;
  border-radius: 100%;
  transform: translate(-50%) !important;
  display: flex;
  flex-shrink: 0;
  background-color: white;
}
.visitor-map-img {
  width: 70%;
}

.milestone-completed {
  background-color: #00c4bf;
}

.milestone-description {
  margin: 0 0 30px 0;
  color: #8e8e93;
}

.last-dot {
  margin-bottom: 5px;
}

@media only screen and (max-width: 980px) {
  .footer-wrapper-home {
    padding: 10px 60px;
    margin: 30px auto 0 auto;
  }
  .footer-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
  }
  .footer-information-home {
    max-width: 90%;
  }
  .partners {
    max-width: 90%;
  }
  .roadmap-footer {
    max-width: 90%;
  }
}

@media only screen and (max-width: 695px) {
  .footer-wrapper-home {
    padding: 10px 40px;
  }
  .footer-home {
    display: flex;
    flex-direction: column;
  }
  .roadmap-footer {
    margin: 40px 0;
  }
}

@media only screen and (max-width: 560px) {
  .footer-wrapper-home {
    padding: 10px 30px;
  }
}
