.navbar-mobile--wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 60px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: none;
  background-color: white;
  font-family: "Cabin", sans-serif;
}
.navbar-mobile {
  display: flex;
  justify-content: space-between;
  color: #25313c;
  background-color: white;
}

.mobile-link {
  min-width: 600px;
  padding: 10px 0;
  font-size: 18px;
}

.navbar-mobile--dropdown {
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  position: absolute;
  background-color: white;
  opacity: 0.98;
  padding: 40px 0;
  max-width: 1300px;
}
.hidden {
  display: none;
}
@media only screen and (max-width: 900px) {
  .navbar-mobile--wrapper {
    display: block;
  }
}

@media only screen and (max-width: 670px) {
  .navbar-mobile--wrapper {
    padding: 15px 40px;
  }
  .macocu-logo--mobile {
    margin-top: 5px;
    max-width: 220px;
  }
}

@media only screen and (max-width: 670px) {
  .macocu-logo--mobile {
    margin-top: 8px;
    max-width: 190px;
  }
}

@media only screen and (max-width: 560px) {
  .navbar-mobile--wrapper {
    padding: 10px 30px;
  }
}
