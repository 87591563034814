.workshop-page {
  color: #10152f;
  font-size: 18px;
}
.hamburger {
  display: none;
}
.workshop-banner {
  background-image: url("../../assets/banner-img-min.png");
  max-height: 220px;
  background-position: center;
  margin-top: 30px;
  padding: 40px 300px;
  background-size: cover;
}
.banner-link {
  text-decoration: none;
  color: #7a6d72;
}
/* NAVBAR STYLING */
.workshop-navbar {
  color: #25313c;
  background-color: white;
  padding: 25px 80px;
  position: sticky;
  top: 0;
  z-index: 2;
}
.workshop-nav-links a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.workshop-nav-links a:hover {
  color: #b7a3aa;
}

.workshop-navbar-active {
  box-shadow: 0px 10px 15px rgba(218, 218, 218, 0.4);
}

.workshop-banner h1 {
  width: 70%;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #10152f;
}
.workshop-container {
  padding: 30px 300px;
}
.workshop-container p {
  font-family: "Lora", serif;
  line-height: 28px;
}
.workshop-list {
  list-style: disc;
  line-height: 46px;
}
.workshop-list li {
  font-family: "Lora", serif;
  font-size: 18px;
  text-decoration: none;
}
.workshop-list ul li a {
  text-decoration: none;
}
.workshop-list li:hover {
  cursor: default;
  transform: none;
}
.workshop-info-title {
  font-size: 26px;
  font-weight: 400;
}
.workshop-info-list {
  list-style: inside;
  line-height: 40px;
}
.workshop-info-list li {
  text-decoration: none;
}
.workshop-page-copy p {
  line-height: 1.8;
}
.together {
  display: flex;
  align-items: center;
}
.together p {
  margin-right: 20px;
  font-size: 16px;
  color: rgb(123, 127, 143);
}

/* NAV */
.workshop-page nav {
  background-color: #ffffff;
  height: 90px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding: 0 120px;
  justify-content: space-between;
}
.invited-speaker {
  margin-bottom: 60px;
  background-color: #fff6f2;
  padding: 15px 40px;
  border-radius: 10px;
}
.invited-speaker .paragraph {
  font-weight: 600;
  letter-spacing: 0.3px;
}
.talk-title {
  font-weight: 550;
  line-height: 1.5;
  margin-bottom: 0px;
  margin-top: 20px;
}
.talk-paragraph {
  margin-top: 5px;
}
.capital {
  text-transform: uppercase;
  color: #b7a3aa !important;
  font-size: 28px !important;
}
.workshop-nav-links a {
  margin-right: 30px;
  font-family: "Poppins", sans-serif;
  color: #3f4458;
}
.workshop-logo {
  font-family: "Poppins", sans-serif;
  color: #b7a3aa;
  font-size: 26px;
  font-weight: 700;
  margin-top: 2px;
}
.workshop-logo span {
  color: #83cbcf;
  font-size: 54px;
  font-family: "Poppins", sans-serif;
}
.footer-workshop {
  background-color: #f0efec;
  height: 150px;
  padding: 30px 300px;
  display: flex;
  align-items: center;
}
.footer-paragraph {
  color: grey;
}
.workshop-info-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 40px 0 0 0;
}
.full-proceedings {
  margin-top: 40px;
  line-height: 1.5;
}
/* RESPONSIVENESS */
@media only screen and (max-width: 1300px) {
  .workshop-banner {
    padding: 40px 200px;
  }
  .workshop-container {
    padding: 30px 200px;
  }
  .footer-workshop {
    background-color: #f0efec;
    height: 150px;
    padding: 20px 200px;
    display: flex;
    align-items: center;
  }
  .workshop-banner h1 {
    width: 85%;
    font-weight: 400;
    color: #10152f;
  }
}

@media only screen and (max-width: 1000px) {
  .workshop-page nav {
    padding: 0 60px;
  }
  .workshop-banner {
    padding: 20px 120px 55px 120px;
  }
  .workshop-container {
    padding: 30px 120px;
  }
  .footer-workshop {
    background-color: #f0efec;
    height: 150px;
    padding: 20px 120px;
    display: flex;
    align-items: center;
  }
  .workshop-banner h1 {
    width: 95%;
    font-weight: 400;
    color: #10152f;
  }
}

@media only screen and (max-width: 750px) {
  .workshop-page nav {
    padding: 0 30px;
  }
  .workshop-banner {
    padding: 20px 30px 25px 30px;
  }
  .workshop-container {
    padding: 30px 30px;
    font-size: 16px;
  }
  .footer-workshop {
    background-color: #f0efec;
    height: 150px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
  }
  .workshop-banner h1 {
    width: 95%;
    font-size: 26px;
    line-height: 1.3;
    font-weight: 400;
    color: #10152f;
  }
  .workshop-list li {
    font-size: 16px;
  }
  .workshop-list li span {
    color: red;
  }
  .workshop-logo {
    font-size: 20px;
  }
  .workshop-logo span {
    color: #83cbcf;
    font-size: 44px;
    font-family: "Poppins", sans-serif;
  }
  .capital {
    text-transform: uppercase;
    color: #b7a3aa !important;
    font-size: 24px !important;
  }
  .together p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .workshop-page nav {
    display: none;
  }
  .together a {
    font-size: 14px;
  }
  .workshop-banner h1 {
    width: 95%;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 400;
    color: #10152f;
  }
  .banner-link {
    font-size: 16px;
  }
  .workshop-info-title {
    font-size: 22px;
    font-weight: 400;
  }
}
.workshop-navbar-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workshop-navbar-mobile--wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 60px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: none;
  background-color: white;
}
.workshop-navbar-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #25313c;
  background-color: white;
}

.mobile-link {
  min-width: 600px;
  padding: 3px 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.workshop-navbar-mobile--dropdown {
  display: flex;
  flex-direction: column;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  position: absolute;
  background-color: white;
  opacity: 0.98;
  padding: 40px 0;
  max-width: 1300px;
}
.hidden {
  display: none;
}
@media only screen and (max-width: 480px) {
  .workshop-navbar-mobile--wrapper {
    display: block;
    padding: 0px 20px;
    align-items: center;
  }
}
.workshop-navbar-mobile p {
  margin-bottom: 5px;
}
.grey-navbar-mobile {
  color: rgb(123, 127, 143);
  font-size: 14px;
  font-weight: 300;
}
a {
  text-decoration: none;
  color: #0f7d93;
}

.accepted-papers-title {
  margin: 40px 0 -5px 0;
}
