.corpora-section--wrapper {
  text-align: left;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 120px;
}
.corpora-title {
  font-size: 42px;
  color: #25313c;
  margin: 40px 0 10px 0;
}
.corpora-section--paragraph {
  line-height: 28px;
  font-size: 18px;
  margin: 10px 0 40px 0;
  color: #25313c;
}
.monolingual-corpora-title,
.parallel-corpora-title {
  text-align: left;
}
.select-language--title {
  margin: 5px 0;
  color: #55626c;
  font-weight: 300;
}
.language-results--container {
  display: grid;
  min-width: 70%;
  grid-template-columns: repeat(3, 1fr);
  margin: 20px 0;
}

.available-languages {
  margin: 0 0px auto 2vw;
}

.language-results-and--language-listing {
  display: flex;
}

.available-languages ul {
  list-style: none;
  padding-left: 0;
}

.available-languages ul li {
  color: #55626c;
  margin-bottom: 3px;
  font-size: 18px;
  text-decoration: underline;
}

.available-languages ul li:hover {
  cursor: pointer;
  color: #43dcd6;
  transform: translateY(-0.3px);
  font-weight: 400;
}
.dropdown-list {
  max-width: 220px;
}

.intro-title {
  color: #25313c;
  font-size: 42px;
  margin: 60px 0 0 0;
}
.intro-paragraph {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 60px;
  color: #55626c;
  max-width: 95%;
}
.intro-corpora-monolingual,
.intro-corpora-parallel {
  text-transform: uppercase;
  color: #6e7071;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  letter-spacing: 0.07em;
  margin-bottom: 0;
}
.ul-paragraph-list {
  padding-left: 50px;
}
.paragraph-list {
  text-decoration: none;
  list-style: circle;
  margin-bottom: 10px;
}

.paragraph-list:hover {
  text-decoration: none;
  color: #55626c;
  transform: translateY(0px);
}
/*-- Responsiveness --*/
@media only screen and (max-width: 1122px) {
  .language-results--container {
    display: grid;
    min-width: 70%;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
  }
}

@media only screen and (max-width: 980px) {
  .select-language--title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 670px) {
  .language-results--container {
    display: flex;
    flex-direction: column;
    max-width: 200px;
  }
  .intro-title {
    font-size: 36px;
  }
  .corpora-title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 568px) {
  .language-results-and--language-listing {
    display: flex;
    flex-direction: column;
  }
  .language-results--container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}
