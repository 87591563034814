.footer-wrapper-crowd {
  text-align: left;
  margin: 40px 90px 0 90px;
  max-width: 1300px;
  font-family: "Cabin", sans-serif;
}
.footer-crowd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.footer-icon {
  max-width: 90%;
}
.footer-information {
  max-width: 32%;
}
.funding-hplt {
  width: 35%;
}
.footer-information a {
  text-decoration: none;
  color: #25313c;
}
.footer-info-last-p {
  color: #8e8e93;
  font-size: 15px;
  line-height: 20px;
  margin-top: 5px;
}

.eu-financing-p {
  display: flex;
  flex-direction: column;
}
.eu-container {
  display: flex;
}
.eu-financing-p p {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 7px;
}
.ukri-logo {
  width: 160px;
  margin-left: 10px;
}
.hplt-macocu-logos {
  display: flex;
  margin-bottom: 10px;
}
.macocu-logo {
  width: 180px;
}
.hplt-logo {
  width: 220px;
  margin-left: -3px;
}

@media only screen and (max-width: 1000px) {
  .footer-wrapper-crowd {
    margin: 40px 60px 0 60px;
  }
  .footer-information {
    max-width: 40%;
  }
  .funding-hplt {
    width: 40%;
  }
}

@media only screen and (max-width: 790px) {
  .footer-crowd {
    flex-direction: column;
  }
  .footer-information {
    max-width: 100%;
  }
  .funding-hplt {
    width: 100%;
  }
}

@media only screen and (max-width: 746px) {
  .footer-crowd {
    flex-direction: column;
  }
  .footer-wrapper-crowd {
    margin: 40px 30px 0 30px;
  }
}
