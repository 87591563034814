* {
  scroll-behavior: smooth;
  transition: all 0.1s;
}

.App {
  text-align: center;
  margin: 0 auto;
  font-family: "Cabin", sans-serif;
}

.dropdown-list {
  max-width: 30%;
  text-align: left;
}
