.intro-columns {
  font-size: 18px;
  line-height: 1.59;
  color: #44495c;
}
.intro-title {
  margin-bottom: 20px;
  color: #25313c;
  margin-left: 20px;
  font-size: 40px;
}
.column-container {
  display: flex;
  margin-bottom: 30px;
}
.column {
  background-color: #f2ffff;
  padding: 20px;
  max-width: 29%;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}
.row {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f2ffff;
  border-radius: 5px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}
.row-title {
  font-weight: 700;
}
.third-column-rows,
.row:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 980px) {
  .column-container {
    flex-wrap: wrap;
  }
  .column {
    max-width: 100%;
    margin: 0 0 20px 0;
  }
}
@media only screen and (max-width: 980px) {
  .intro-title {
    font-size: 32px;
  }
}
