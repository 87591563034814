.explore--section {
  display: flex;
  max-width: 1300px;
  margin: auto;
}

.explore-section--left {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.explore-section--title {
  font-size: 42px;
  margin-top: 6px;
  color: #25313c;
}

.explore-bitextor--button,
.explore-macocu--button {
  text-decoration: none;
  font-size: 24px;
  margin: 10px 0px;
  background-color: #00c4bf;
  padding: 4px 12px;
  max-width: 220px;
  border-radius: 5px;
  font-weight: 300;
  color: white;
  text-align: left;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.explore-bitextor--button:hover,
.explore-macocu--button:hover {
  background-color: #43dcd6;
  box-shadow: 0px 10px 15px rgba(167, 237, 251, 0.4);
  color: #fff;
  transform: translateY(-3px);
}
.girl-coding--img {
  max-width: 47%;
}

.icon {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.corset-link {
  margin: auto 0 10px 0;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  width: fit-content;
  font-size: 20px;
  color: #25313c;
  transition: all 0.3s;
}
.corset-link:hover {
  box-shadow: 0px 5px 5px rgba(231, 251, 255, 0.4);
  color: #43dcd6;
  text-decoration: none;
  transition: all 0.3s;
}

@media only screen and (max-width: 980px) {
  .explore-section--title {
    font-size: 36px;
    margin-top: 6px;
  }
  .explore-bitextor--button,
  .explore-macocu--button {
    font-size: 20px;

    padding: 3px 10px;
  }
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .explore--section {
    flex-direction: column;
    justify-content: center;
  }
  .girl-coding--img {
    max-width: 45%;
    margin: 13px auto;
  }
  .explore-section--left {
    text-align: center;
  }
  .explore-bitextor--button,
  .explore-macocu--button {
    font-size: 19px;
    margin: 10px auto;
    padding: 3px 10px;
    min-width: 220px;
  }
  .corset-link {
    margin: 10px auto;
  }
}
